import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

const IndexView = () => import(/* webpackChunkName: "about" */ '../views/index.vue');
const ListView = () => import(/* webpackChunkName: "about" */ '../views/list.vue');
const InfoView = () => import(/* webpackChunkName: "InfoView" */ '../views/info.vue');
const ScanInfoView = () => import(/* webpackChunkName: "ScanInfoView" */ '../views/scanInfo.vue');
const DemoView = () => import(/* webpackChunkName: "about" */ '../views/Demo.vue');
const FormView = () => import(/* webpackChunkName: "about" */ '../views/Form.vue');
const AddressList = () => import(/* webpackChunkName: "about" */ '../views/AddressList.vue');
const AddressEdit = () => import(/* webpackChunkName: "about" */ '../views/AddressEdit.vue');
const bizFlowLog = () => import(/* webpackChunkName: "bizFlowLog" */ '../views/bizFlowLog.vue');

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {path: '/index', name: 'index', component: IndexView},
  {path: '/list', name: 'list', component: ListView},
  {path: '/info/:id', name: 'info', component: InfoView},
  {path: '/scanInfo/:id', name: 'info', component: ScanInfoView},
  {path: '/demo', name: 'demo', component: DemoView},
  {path: '/form', name: 'form', component: FormView},
  {path: '/addressList', name: 'addressList', component: AddressList},
  {path: '/addressEdit', name: 'addressEdit', component: AddressEdit},
  {path: '/bizFlowLog', name: 'bizFlowLog', component: bizFlowLog}
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'myActive'
})

export default router
