<template>
  <div class="home">
    <MyNavBar title="demo" :leftArrow="false"/>

    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>


    <MyTabBar active="home"/>

  </div>

</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import MyNavBar from '@/components/MyNavBar.vue';
import MyTabBar from '@/components/MyTabBar.vue';

export default {
  name: 'Home',
  components: {
    HelloWorld,
    MyNavBar,
    MyTabBar
  }
}
</script>
<style lang="less">
  .home {
    text-align: center;
    color: #2c3e50;
  }
</style>
