import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.css'
import './assets/css/ace.css'
import { get, get2, post, put } from './api/api';
// 字典标签组件
// import DictTag from './components/DictTag'

Vue.use(Vant)
// Vue.component('DictTag', DictTag)
Vue.config.productionTip = false


Vue.prototype.get = (query, urlStr) => get(query, urlStr);
Vue.prototype.get2 = (query, urlStr) => get2(query, urlStr);
Vue.prototype.post = (obj, urlStr) => post(obj, urlStr);
Vue.prototype.put = (obj, urlStr) => put(obj, urlStr);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
