<template>
  <van-tabbar v-model="selActive"  active-color="#ee0a24" :placeholder=true>
    <van-tabbar-item name="home" to="/" icon="home-o">标签</van-tabbar-item>
    <van-tabbar-item name="demo" to="/demo" icon="search">标签</van-tabbar-item>
    <van-tabbar-item name="form" to="/form" icon="edit">标签</van-tabbar-item>
  </van-tabbar>
</template>

<script>
  export default {
    name: 'MyFooter',
    props: {
      active: String,
    },
    data(){
      return {
        selActive: this.active  //通过data, 定义新变量currentSearch, 这样currentSearch的值变更时，不会影响父组件传来的search的值
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
