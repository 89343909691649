import request from '../utils/request';

export const get = (query, urlStr) => {
    return request.get(urlStr, {
        params: query,
    });
};

export const get2 = (query, urlStr) => {
    return request.get(urlStr+query);
};

export const post = (obj, urlStr) => {
    return request.post(urlStr, obj);
};
export const put = (obj, urlStr) => {
    return request.put(urlStr, obj);
};
