import axios from 'axios';
import data from '../../public/data.json'
export const baseUrl = data.url;

let AbaseURL = process.env.NODE_ENV == 'development' ? window._CONFIG['localURL'] : window._CONFIG['domianURL'];

const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    baseURL: AbaseURL
     //baseURL: 'http://47.103.59.143:8701/api/'
    //timeout: 5000
});

//请求拦截器
service.interceptors.request.use(
    config => {
        config.headers.common['Account'] = encodeURI(localStorage.getItem('ms_username'));
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

//响应拦截器
service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return response.data;
        } else {
            Promise.reject();
        }
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

export default service;
