<template>
  <div>
    <van-nav-bar
      :title="myTitle"
      :left-arrow="leftArrow"
      :fixed=true
      :placeholder=true
      @click-left="onClickLeft"
    />
  </div>
</template>

<script>
export default {
  name: 'MyNavBar',
  props: {
    title: String,
    leftArrow: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return {
      myTitle: this.title,
      myLeftArrow: this.leftArrow
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
